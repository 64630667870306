import { ComponentProps, ReactNode } from 'react';

import { DropdownSelect } from 'atoms/inputs/DropdownSelect';
import { BodyM } from 'atoms/typography';

import { Field } from '../Field';

type Props = {
  id: string;
  name: string;
  initialValue?: { label: ReactNode; value: string };
  label?: ReactNode | string;
  required?: boolean;
} & Pick<ComponentProps<typeof DropdownSelect>, 'disabled' | 'options'>;

export const FormSelect = ({
  name,
  label,
  id,
  options,
  initialValue,
  disabled: disabledProp,
  required,
}: Props) => {
  if (!options.length) {
    return null;
  }

  return (
    <Field
      name={name}
      defaultValue={initialValue?.value}
      render={({ error, handleChange, value, disabled }) => {
        const selectedOption =
          options.find(option => option.value === value) || initialValue;
        return (
          <>
            {label && (
              <BodyM bold color="currentColor" as="label" htmlFor={id}>
                {label}
              </BodyM>
            )}
            <DropdownSelect
              size="medium"
              disabled={disabled || disabledProp}
              value={selectedOption?.value}
              options={options}
              onChange={newValue => {
                handleChange(newValue);
              }}
            />
            {required && (
              <input
                type="radio"
                className="sr-only"
                name={name}
                value={selectedOption?.value}
                required
              />
            )}
            {error && <BodyM color="var(--c-red-600)">{error}</BodyM>}
          </>
        );
      }}
    />
  );
};
