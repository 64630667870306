import { Navigate, Outlet } from 'react-router-dom';

import { NBA_PLAY } from '@sorare/core/src/constants/__generated__/routes';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

const Layout = () => {
  const {
    flags: { useNbaPostseasonRevamp = false },
  } = useFeatureFlags();

  if (!useNbaPostseasonRevamp) {
    return <Navigate to={NBA_PLAY} replace />;
  }

  return <Outlet />;
};

export default Layout;
