import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Dialog } from '@sorare/core/src/components/dialog';
import { useOneTimeDialogContext } from '@sorare/core/src/contexts/oneTimeDialog';
import { OneTimeDialog } from '@sorare/core/src/contexts/oneTimeDialog/Provider';
import { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

import background from 'components/clubShop/assets/background.jpg';
import clubshop_vertical from 'components/clubShop/assets/clubshop-vertical.svg';

const Wrapper = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--quadruple-unit);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(6 * var(--unit));
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: var(--double-unit);
  right: var(--double-unit);
`;

const Introducing = styled.div`
  margin-top: var(--quadruple-unit);
  padding: 0 var(--double-unit);

  font: 14px/24px 'Kreuz Extended Light';
  text-transform: uppercase;
  border: 1px solid var(--c-white);
  border-radius: 1em;
`;

const ClubShopWithLogo = styled.img`
  max-width: calc(40 * var(--unit));
`;

const Description = styled.div`
  color: var(--c-nd-400);
`;

export const ClubShopIntroDialog = () => {
  const { sawDialog } = useOneTimeDialogContext();

  return (
    <OneTimeDialog
      dialogId={LIFECYCLE.sawMlbClubShopIntro}
      show={!sawDialog(LIFECYCLE.sawMlbClubShopIntro)}
    >
      {({ onClose, open }) => (
        <Dialog maxWidth="xs" open={open}>
          <Wrapper>
            <CloseButton
              color="tertiary"
              small
              icon={faTimes}
              onClick={onClose}
            />
            <Introducing>
              <FormattedMessage
                id="baseballClubShop.IntroDialog.introducing"
                defaultMessage="Introducing"
              />
            </Introducing>
            <ClubShopWithLogo src={clubshop_vertical} alt="" />
            <Description>
              <FormattedMessage
                id="baseballClubShop.IntroDialog.description"
                defaultMessage="Welcome to the Sorare Club Shop! This is your headquarters for
                  all things utility in the game—as well as special merch drops
                  (coming soon). Elevate your game by unlocking boosts, swaps, and
                  more—all available for purchase with your Coins."
              />
            </Description>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <FormattedMessage
                id="baseballClubShop.IntroDialog.letsGo"
                defaultMessage="Let's Go!"
              />
            </Button>
          </Wrapper>
        </Dialog>
      )}
    </OneTimeDialog>
  );
};
