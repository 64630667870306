import { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { DialogOnboarding } from 'components/onboarding/Dialog';
import { mobileAndAbove } from 'style/mediaQuery';

import coins from './assets/coins.png';
import mlbItems from './assets/mlbItems.png';

// These messages are used in both Football and MLB
// If you edit them, be cautious that it applies to both.

export const howToGetCoinsMessages = defineMessages({
  content2: {
    id: 'ClubShop.OnboardingDialog.Step1.Content2',
    defaultMessage:
      'The more teams you compete with – and the better they perform – the more Coins you’ll earn.',
  },
});

export const howToUseCoinsMessages = defineMessages({
  title: {
    id: 'ClubShop.OnboardingDialog.Step2.Title',
    defaultMessage: 'Redeem Coins for Rewards',
  },
  content1: {
    id: 'ClubShop.OnboardingDialog.Step2.Content1',
    defaultMessage:
      'Spend your Coins on a variety of in-game and real-life rewards:',
  },
  content3: {
    id: 'ClubShop.OnboardingDialog.Step2.Content3',
    defaultMessage:
      '<Bold>Boosts</Bold> – Improve the Experience Points (XP) of your cards.',
  },
  content4: {
    id: 'ClubShop.OnboardingDialog.Step2.Content4',
    defaultMessage:
      '<Bold>Tickets + Merch</Bold> (Coming Soon) – Pick from a range of match tickets, jerseys, and signed merch.',
  },
});

export const StepWrapper = styled(Vertical).attrs({ gap: 2 })`
  margin-top: var(--triple-unit);
  text-align: left;
  padding: var(--triple-unit);
`;

export const StepTextList = styled.ul`
  margin: 0 var(--triple-unit);
  padding: 0;
  li {
    list-style-type: circle;
    & + li {
      margin-top: var(--half-unit);
    }
  }
`;

const StepImage = styled.img`
  align-self: center;
  width: 100px;
  @media ${mobileAndAbove} {
    width: 200px;
  }
`;

export const CoinsStepImage = () => <StepImage src={coins} alt="" />;

export const BaseballItemsStepImage = () => <StepImage src={mlbItems} alt="" />;

type Props = {
  steps: (ReactElement | ((args: { onNext: () => void }) => ReactElement))[];
  open: boolean;
  onClose: () => void;
};
export const ClubShopInformationDialog = ({ steps, open, onClose }: Props) => {
  return <DialogOnboarding open={open} steps={steps} onClose={onClose} />;
};
