import { faBasketball } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from 'atoms/icons';
import { unitMapping } from 'lib/style';

type Props = {
  className?: string;
  size?: keyof typeof unitMapping;
  title?: string;
  color?: string;
};

export const NBABall = ({ className, size = 2, title, color }: Props) => (
  <FontAwesomeIcon
    icon={faBasketball}
    className={className}
    width={size * 8}
    title={title}
    color={color}
  />
);
