import { useFetch } from 'hooks/useFetch';
import { fileUrl } from 'lib/gitlab';
import { LazyMarkdown } from 'routing/LazyMarkdown';

type Props = {
  document: string;
  skipHtml?: boolean;
  escapeHtml?: boolean;
};

export const TextDocument = ({
  document,
  skipHtml,
  escapeHtml = true,
}: Props) => {
  const data = useFetch(fileUrl(document));
  return (
    <LazyMarkdown data={data} skipHtml={skipHtml} gfm html={!escapeHtml} />
  );
};
