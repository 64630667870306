import { TypedDocumentNode } from '@apollo/client';
import { DocumentNode, IntrospectionQuery, buildClientSchema } from 'graphql';
import { print } from 'graphql/language/printer';

import { IS_TEST_RUNNER } from 'config';

// Outside local dev environment, queries are optimized and transformed to stored operations
// Meaning the query used in production are very different from the ones used in development
// Preprocessing the query before sending it to the server allows us to use the same query in both environments
export const optimizeQuery = {
  current: <T extends TypedDocumentNode<any, any>>(query: T): T => query,
};

export const initializeOptimizeQuery = async (callback: () => void) => {
  if (process.env.NODE_ENV !== 'development' || IS_TEST_RUNNER) {
    callback();
    return;
  }
  // HACK https://github.com/dotansimha/graphql-code-generator/discussions/8784
  process.hrtime = process.hrtime || (() => [0, 0]);
  const { optimizeDocuments } = await import(
    '@graphql-tools/relay-operation-optimizer'
  );
  // HACK use import.meta.glob to avoid loading this file in typescript
  const { default: schemaJson } = await import.meta
    .glob<Record<'default', unknown>>('../../../graphql-schema.json', {
      eager: false,
    })
    ['../../../graphql-schema.json']();

  const schema = schemaJson as unknown as IntrospectionQuery;
  const clientSchema = buildClientSchema(schema);
  const optimizedQueries: Record<string, DocumentNode> = {};

  optimizeQuery.current = <T extends TypedDocumentNode<any, any>>(
    query: T
  ): T => {
    const s = print(query);
    if (!optimizedQueries[s]) {
      // eslint-disable-next-line prefer-destructuring
      optimizedQueries[s] = optimizeDocuments(clientSchema, [query])[0];
    }
    return optimizedQueries[s] as T;
  };
  callback();
};
