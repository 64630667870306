import { ReactElement, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { CloseButton } from 'atoms/buttons/CloseButton';
import { Dots } from 'atoms/layout/Dots';
import { Dialog } from 'components/dialog';
import { useTransitionApi } from 'hooks/ui/useTransitionApi';
import { glossary } from 'lib/glossary';
import { laptopAndAbove } from 'style/mediaQuery';

import { Footer } from './Footer';
import { Stepper } from './Stepper';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  view-transition-name: onboarding-dialog-content;
  /* For mobile, ensures buttons are at bottom of screen */
  height: 100%;
  @media ${laptopAndAbove} {
    width: 480px;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: var(--double-unit);
  right: var(--double-unit);
`;
const BackButton = styled(Button)`
  &.transparent {
    color: var(--c-nd-600);
  }
`;

type Props = {
  open: boolean;
  steps: (
    | ReactElement
    | ((args: {
        onNext: () => void;
        onBack?: (() => void) | null;
      }) => ReactElement)
  )[];
  onClose?: () => void;
  onDone?: () => void;
  hideFooter?: boolean;
  showStepper?: boolean;
};
export const DialogOnboarding = ({
  open,
  steps,
  onClose,
  onDone,
  hideFooter = false,
  showStepper = false,
}: Props) => {
  const [step, setStep] = useState(0);
  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;
  const { updateDOM } = useTransitionApi();

  useLayoutEffect(() => {
    // When opening, set to first slide
    if (open) {
      setStep(0);
    }
  }, [open]);

  const onNext = () =>
    updateDOM(() =>
      isLastStep
        ? onDone?.() || onClose?.()
        : setStep(n => (n + 1) % steps.length)
    );

  const onBack = () => updateDOM(() => setStep(n => n - 1));

  const currentStep = steps[step];
  const currentStepElement =
    typeof currentStep === 'function'
      ? currentStep({
          onNext,
          onBack: isFirstStep ? null : onBack,
        })
      : currentStep;

  return (
    <Dialog maxWidth={false} open={open} onClose={onClose} hideHeader>
      <DialogContent>
        {showStepper && (
          <Stepper setStep={setStep} step={step} length={steps.length} />
        )}
        {onClose && (
          <CloseButtonWrapper>
            <CloseButton onClose={onClose} />
          </CloseButtonWrapper>
        )}
        {currentStepElement}
        {!hideFooter && (
          <Footer
            key={step}
            left={
              !isFirstStep ? (
                <BackButton size="medium" color="transparent" onClick={onBack}>
                  <FormattedMessage {...glossary.back} />
                </BackButton>
              ) : null
            }
            middle={
              steps.length > 1 ? (
                <Dots
                  count={steps.length}
                  current={step}
                  onChange={n => updateDOM(() => setStep(n))}
                />
              ) : null
            }
            right={
              <Button size="medium" color="primary" onClick={onNext}>
                {isLastStep ? (
                  <FormattedMessage
                    id="DialogOnboarding.Start"
                    defaultMessage="Start"
                  />
                ) : (
                  <FormattedMessage {...glossary.next} />
                )}
              </Button>
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
