import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { NBA_PLAY_CHASE_EVENTTYPE_FIXTURE_USERGROUP_USER } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { PageParams } from './__generated__/routeParams';

const PlayProFixtureChaseUserGroupRedirectionPage = () => {
  const {
    fixture: fixtureSlug,
    userGroup: userGroupSlug,
    eventType,
  } = useTypedParams<PageParams>();
  const { currentUser } = useCurrentUserContext();

  return (
    <Navigate
      to={generatePath(NBA_PLAY_CHASE_EVENTTYPE_FIXTURE_USERGROUP_USER, {
        fixture: fixtureSlug,
        userGroup: userGroupSlug,
        user: currentUser?.slug ?? '',
        eventType,
      })}
      replace
    />
  );
};

export default PlayProFixtureChaseUserGroupRedirectionPage;
