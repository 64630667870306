import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { WearableSize } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, HeadlineL, HeadlineS } from '@sorare/core/src/atoms/typography';
import { Dialog } from '@sorare/core/src/components/dialog';
import { Attributes } from '@sorare/core/src/components/settings/PostalAddressForm';
import { glossary } from '@sorare/core/src/lib/glossary';

import { AddressPreview } from 'components/rewards/deliverableItem/DeliverableItemFooter/AdressPreview';
import {
  BorderedBox,
  Column,
  Content,
  Footer,
  TwoColumn,
  Wrapper,
} from 'components/rewards/deliverableItem/DeliverableItemFooter/ui';
import { DeliverableItemPreview } from 'components/rewards/deliverableItem/DeliverableItemPreview';

import { DeliveryConfirmed_DeliverableItem } from './__generated__/index.graphql';

type Props = {
  deliverableItem: DeliveryConfirmed_DeliverableItem;
  selectedSize?: WearableSize | null;
  address: Attributes;
  hideSignedVariant?: boolean;
  onDone: () => void;
};

export const DeliveryConfirmed = ({
  deliverableItem,
  selectedSize,
  address,
  hideSignedVariant,
  onDone,
}: Props) => {
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onDone}>
      <Wrapper>
        <Content center>
          <Vertical gap={4}>
            <Vertical className="text-center">
              <HeadlineL as="h2">
                <FormattedMessage
                  id="DeliveryConfirmed.title"
                  defaultMessage="It's Yours!"
                />
              </HeadlineL>
              <BodyS as="p" color="var(--c-nd-600)">
                <FormattedMessage
                  id="DeliveryConfirmed.description"
                  defaultMessage="Thank you for submitting your information. You will receive an email confirmation shortly."
                />
              </BodyS>
            </Vertical>
            <TwoColumn>
              <Column>
                <DeliverableItemPreview
                  deliverableItem={deliverableItem}
                  hideSignedVariant={hideSignedVariant}
                />
              </Column>
              <Column>
                <BorderedBox>
                  <Vertical>
                    {selectedSize && (
                      <Vertical>
                        <HeadlineS as="h3">
                          <FormattedMessage
                            id="DeliveryConfirmed.jerseySize"
                            defaultMessage="Jersey size"
                          />
                        </HeadlineS>
                        <BodyS as="p">{selectedSize}</BodyS>
                      </Vertical>
                    )}
                    <AddressPreview address={address} />
                  </Vertical>
                </BorderedBox>
              </Column>
            </TwoColumn>
          </Vertical>
        </Content>
        <Footer>
          <Button onClick={onDone} color="primary" size="medium">
            <FormattedMessage {...glossary.done} />
          </Button>
        </Footer>
      </Wrapper>
    </Dialog>
  );
};

DeliveryConfirmed.fragments = {
  deliverableItem: gql`
    fragment DeliveryConfirmed_DeliverableItem on DeliverableItemInterface {
      slug
      id
      ...DeliverableItemPreview_deliverableItem
    }
    ${DeliverableItemPreview.fragments.deliverableItem}
  ` as TypedDocumentNode<DeliveryConfirmed_DeliverableItem>,
};
