import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Container, FullWidth } from '@sorare/core/src/atoms/layout/Container';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { NavigationTabs } from '@sorare/core/src/atoms/navigation/NavigationTabs';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { ClubShopButton } from '@sorare/core/src/components/clubShop/ClubShopButton';
import { CoinAmount } from '@sorare/core/src/components/clubShop/CoinAmount';
import {
  MLB,
  MLB_CLUB_SHOP,
  MLB_CLUB_SHOP_MERCH,
  MLB_CLUB_SHOP_MY_ITEMS,
  MLB_CLUB_SHOP_UTILITY,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCoinBalance } from '@sorare/core/src/hooks/clubShop/useCoinBalance';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import { useSafePreviousNavigate } from '@sorare/core/src/hooks/useSafePreviousNavigate';
import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { ClubShopInformationDialog } from 'components/clubShop/ClubShopInformationDialog';
import { ClubShopIntroDialog } from 'components/clubShop/ClubShopIntroDialog';
import background from 'components/clubShop/assets/background.jpg';
import clubshop_horizontal from 'components/clubShop/assets/clubshop-horizontal.svg';
import clubshop_vertical from 'components/clubShop/assets/clubshop-vertical.svg';

const Header = styled.header`
  margin: var(--unit) 0;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${tabletAndAbove} {
    padding-top: var(--triple-unit);
  }
`;

const NavigationBar = styled(SBHorizontal).attrs({ gap: 0 })`
  width: 100%;
  padding: var(--quadruple-unit) var(--triple-unit) var(--unit)
    var(--triple-unit);
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--unit);
  gap: var(--double-unit);
  align-items: center;

  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`;

const LogoCol = styled.img`
  max-width: calc(30 * var(--unit));
  @media ${tabletAndAbove} {
    max-width: calc(45 * var(--unit));
  }
`;

const CoinBalanceCol = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: var(--double-unit);
  justify-content: flex-end;

  @media ${tabletAndAbove} {
    display: inline-flex;
    flex-direction: column;
    gap: var(--unit);
    align-items: flex-end;
  }
`;

const UnderlinedText = styled(LabelM)`
  text-decoration: underline;
`;

export const ClubShopLayout = () => {
  const { formatMessage } = useIntl();
  const isTabletAndAbove = useIsTabletAndAbove();
  const { coinBalance } = useCoinBalance();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const onGoBack = useSafePreviousNavigate(MLB);

  const tabItems = [
    {
      path: MLB_CLUB_SHOP,
      label: formatMessage({
        id: 'ClubShop.Tabs.All',
        defaultMessage: 'All',
      }),
    },
    {
      path: MLB_CLUB_SHOP_UTILITY,
      label: formatMessage({
        id: 'ClubShop.Tabs.Utility',
        defaultMessage: 'Utility',
      }),
    },
    {
      path: MLB_CLUB_SHOP_MERCH,
      label: formatMessage({
        id: 'ClubShop.Tabs.Merch',
        defaultMessage: 'Merch',
      }),
    },
    {
      path: MLB_CLUB_SHOP_MY_ITEMS,
      label: formatMessage({
        id: 'ClubShop.Tabs.MyItems',
        defaultMessage: 'My Items',
      }),
    },
  ].map(({ path, ...rest }) => ({ to: path, path, ...rest }));

  return (
    <RequireAuth>
      <ClubShopIntroDialog />
      <ClubShopInformationDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
      <FullWidth>
        <Header>
          {!isTabletAndAbove && (
            <NavigationBar>
              <IconButton
                color="tertiary"
                small
                icon={faArrowLeft}
                onClick={onGoBack}
              />
              <ClubShopButton to={MLB_CLUB_SHOP}>
                <CoinAmount amount={coinBalance} />
              </ClubShopButton>
            </NavigationBar>
          )}
          <Container>
            <FlexContainer>
              {isTabletAndAbove ? (
                <LogoCol src={clubshop_horizontal} alt="" />
              ) : (
                <LogoCol src={clubshop_vertical} alt="" />
              )}
              <CoinBalanceCol>
                <button type="button" onClick={() => setShowDialog(true)}>
                  <UnderlinedText color="var(--c-white)">
                    <FormattedMessage
                      id="ClubShop.Header.Help"
                      defaultMessage="How to earn Coins?"
                    />
                  </UnderlinedText>
                </button>
                {isTabletAndAbove && (
                  <ClubShopButton to={MLB_CLUB_SHOP}>
                    <CoinAmount amount={coinBalance} />
                  </ClubShopButton>
                )}
              </CoinBalanceCol>
            </FlexContainer>
            <NavigationTabs items={tabItems} />
          </Container>
        </Header>
      </FullWidth>
      <Container>
        <Vertical gap={0}>
          <Outlet />
        </Vertical>
      </Container>
    </RequireAuth>
  );
};

export default ClubShopLayout;
