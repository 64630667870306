import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { MarketCredit } from '@sorare/core/src/atoms/icons/MarketCredit';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import { useConversionCreditForPrice } from 'hooks/useConversionCreditForPrice';

const Label = styled(LabelS)`
  white-space: nowrap;
`;
type Props = {
  price: MonetaryAmountParams;
  sport: Sport;
  Wrapper: React.FC<{ children: React.ReactNode }>;
};

export const AmountSaved = ({ price, sport, Wrapper }: Props) => {
  const { absoluteConversionCreditMonetaryAmount } =
    useConversionCreditForPrice({
      price,
      sport,
    });

  const { main } = useAmountWithConversion({
    monetaryAmount: absoluteConversionCreditMonetaryAmount,
  });

  if (!absoluteConversionCreditMonetaryAmount.eur) return null;
  return (
    <Wrapper>
      <MarketCredit />
      <Label bold>
        <FormattedMessage
          id="PlayerDrawerToken.marketCredit.discount.cornerBadge"
          defaultMessage="{amount} saved"
          values={{ amount: main }}
        />
      </Label>
    </Wrapper>
  );
};
