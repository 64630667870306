import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  PreloadQueryFunction,
  ServerError,
  createQueryPreloader,
  setLogVerbosity,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { Consumer, createConsumer } from '@rails/actioncable';
import * as Sentry from '@sentry/core';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import cookie from 'react-cookies';
import { RouteMatch, RouteObject, matchRoutes } from 'react-router-dom';

import { DebugProvider } from 'contexts/debug/Provider';
import { useDeviceFingerprintContext } from 'contexts/deviceFingerprint';
import { useSentryContext } from 'contexts/sentry';
import { Level } from 'contexts/snackNotification';
import { OperationStoreClient } from 'gql/OperationStoreClient';
import introspectionResult from 'gql/introspectionResult.json';
import { useDebugLink } from 'hooks/graphql/useDebugLink';
import { useTMLink } from 'hooks/graphql/useTMLink';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { notificationsQueue } from 'hooks/useNotificationsQueue';
import { initializeOptimizeQuery } from 'lib/gql/optimizeQuery';
import {
  client as clientWithCredentials,
  isNoInternetConnectionError,
  xsrfCookieName,
  xsrfReceivingHeaderName,
} from 'lib/http';

import { GraphqlContext } from '.';
import {
  API_ROOT,
  API_URL,
  HOSTNAME,
  IS_PLAYGROUND,
  IS_TEST_RUNNER,
  WS_ROOT,
  isMockprod,
  isProduction,
  isStaging,
} from '../../config';
import { ActionCableLink } from './ActionCableLink';
import { typePolicies } from './typePolicies';

const authenticatedFetch = buildAxiosFetch(clientWithCredentials);

const cable = () => createConsumer(WS_ROOT);

const MAX_RETRIES = 8;
const INITIAL_RETRY_DELAY = 1000;

if (import.meta.env.MODE === 'development') {
  setLogVerbosity('debug');
}

const createCache = () =>
  new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: IS_PLAYGROUND ? undefined : typePolicies,
  });

export const coreCache = createCache();

const hasSubscriptionOperation = ({
  query: { definitions },
}: {
  query: { definitions: any };
}) =>
  definitions.some(
    ({ kind, operation }: { kind: string; operation: string }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );

interface Props {
  children: ReactNode;
  uri?: string;
  basename?: string;
  routeObjects?: RouteObject[];
  disableGraphQLErrorsReport?: boolean;
  locale: string;
}

function trimSlash(path: string): string {
  return path.replace(/\/\*?$/, '');
}

function prefixWithSlash(path: string): string {
  return path[0] === '/' ? path : `/${path}`;
}

function getNormalizedName(branches: RouteMatch[]) {
  let pathBuilder = '';
  if (branches) {
    for (const branch of branches) {
      const { route } = branch;
      if (route?.path) {
        const { path } = route;
        pathBuilder = trimSlash(pathBuilder) + prefixWithSlash(path);
      }
    }
  }
  return pathBuilder;
}

// allow access to the client from outside react context
// used for react-router data route loaders https://reactrouter.com/en/main/route/loader
const apolloClient: {
  current: ApolloClient<NormalizedCacheObject> | null;
} = {
  current: null,
};
export const preloadQuery: {
  current: PreloadQueryFunction | null;
} = {
  current: null,
};

// According to the Apollo documentation (https://www.apollographql.com/docs/react/v2/data/error-handling/)
// once you define an ErrorLink, you will be triggered whatever the `errorPolicy` that is specified in the query
// and the only way for you to prevent the GraphQLError from being processed is to condition its processing
// based on the operation name.
const SILENCE_GQL_ERRORS_FROM_OPERATION_NAMES = [
  // ignore errors for when the joinSecret is invalid
  'DialogLeagueQuery',
  'ChartQuery',
];

function getOperationNameAndId(body: BodyInit | null | undefined) {
  try {
    const { operationName, extensions: { operationId } = {} } = JSON.parse(
      body as string
    ) as {
      operationName: string;
      extensions?: { operationId?: string };
    };
    return { operationName, operationId };
  } catch {
    return { operationName: '', operationId: undefined };
  }
}

export const StaticLocaleGraphqlProvider = ({
  children,
  uri = API_URL,
  disableGraphQLErrorsReport = false,
  locale,
  basename,
  routeObjects,
}: Props) => {
  const { isMobileApp } = useIsMobileApp();

  const envApiKey =
    (import.meta.env.MODE === 'development' &&
      isProduction &&
      process.env.SORARE_COM_API_KEY) ||
    undefined;

  const apiKey = useRef<string | undefined>(envApiKey);
  const setApiKey = (s: string | undefined) => {
    apiKey.current = s;
  };

  const deviceFingerprint = useRef<string>();
  const { deviceFingerprint: generateDeviceFingerprint } =
    useDeviceFingerprintContext();

  const [wsCable, setWsCable] = useState<Consumer>(cable());
  const { sendSafeError } = useSentryContext();
  const activeQueries = useRef(0);
  const [isInitialized, setIsInitialized] = useState(false);

  const debugLink = useDebugLink();
  const tmLink = useTMLink({ path: new URL(uri).pathname });

  const link = useMemo(() => {
    /* eslint-enable consistent-return */
    const onErrorLink = onError(
      ({ networkError, graphQLErrors, operation }) => {
        if (networkError) {
          if ('statusCode' in networkError) {
            // In the case of 429 network error for signIn, we make this error silent cause we want to have
            // an inline error instead of a notification: (error is handled at a lower level in the form directly)
            if (
              networkError.statusCode === 429 &&
              operation?.operationName === 'SignInMutation'
            ) {
              return undefined;
            }

            // In the case of 500 network error, do not report to Sentry because the backend has already
            if (networkError.statusCode === 500) {
              notificationsQueue.addNotification('errors', {
                errors: [networkError.message],
              });
              return undefined;
            }

            if (networkError.statusCode === 503) {
              const serverError = networkError as ServerError;

              const { start, end, msg } =
                (typeof serverError.result !== 'string' &&
                  serverError.result.maintenance) ||
                {};

              if (msg) {
                notificationsQueue.addNotification('serviceUnderMaintenance', {
                  start,
                  end,
                  msg,
                });
                return undefined;
              }
            }
          }

          if (isNoInternetConnectionError(networkError)) {
            // skip the snack notification on mobile apps: they already have native handling
            if (!isMobileApp) {
              notificationsQueue.addNotification(
                'lostInternetConnection',
                undefined,
                {
                  level: Level.ERROR,
                  ignoreDuplicates: true,
                }
              );
            }
          } else {
            // generic network error handling
            notificationsQueue.addNotification('errors', {
              errors: [networkError.message],
            });
          }
          const serverError = networkError as ServerError;
          const message =
            typeof serverError.result === 'string'
              ? serverError.result
              : serverError.result?.message;

          if (!disableGraphQLErrorsReport && message) {
            const error = `NetworkError: "${message}" in ${operation.operationName}`;

            Sentry.addBreadcrumb({ level: 'debug', data: { networkError } });
            sendSafeError(new Error(error, { cause: networkError }));
          }

          return undefined;
        }

        if (
          graphQLErrors &&
          !SILENCE_GQL_ERRORS_FROM_OPERATION_NAMES.includes(
            operation.operationName
          )
        ) {
          if (
            graphQLErrors.find(e => e.extensions?.code === 'UNAUTHORIZED') &&
            !IS_PLAYGROUND &&
            !IS_TEST_RUNNER
          ) {
            // session has expired, redirect to login page the hard way as we're too high in the provider stack
            // to rely on the Config/CurrentUser/Connection contexts
            // eslint-disable-next-line no-restricted-properties
            window.location.replace(
              `/?action=signin&redirectUrl=${encodeURIComponent(
                // eslint-disable-next-line no-restricted-properties
                `${window.location.pathname}${window.location.search}`
              )}`
            );
            // eslint-disable-next-line no-console
            console.error(
              `Session has expired, redirecting to login page:\n ${graphQLErrors.map(e => `\t${e.message}`).join('\n')}`
            );
            return undefined;
          }

          if (IS_TEST_RUNNER) {
            // eslint-disable-next-line no-console
            console.error(
              'GRAPHQL_ERROR',
              operation.operationName,
              graphQLErrors.map(e => e.message).join('\n')
            );
          }
          notificationsQueue.addNotification('errors', {
            errors: graphQLErrors.map(e => e.message),
          });

          if (!disableGraphQLErrorsReport) {
            Sentry.addBreadcrumb({ level: 'debug', data: { graphQLErrors } });
            Sentry.withScope(scope => {
              scope.setTag('kind', operation.operationName);
              scope.setExtra('variables', operation.variables);
              graphQLErrors?.forEach(err => {
                if (err.extensions?.code === 'NOT_FOUND') {
                  // do not report "not found" errors
                  return;
                }

                const error = `GraphqlError: "${err.message}" in ${
                  operation.operationName
                } > ${(err.path ?? []).join(' > ')})`;

                sendSafeError(new Error(error, { cause: err }));
              });
            });
          }
        }

        return undefined;
      }
    );

    const retryLink = new RetryLink({
      delay: {
        initial: INITIAL_RETRY_DELAY,
        jitter: true,
        max: Infinity,
      },
      attempts(count, _operation, error) {
        if (typeof (error as ServerError)?.statusCode === 'number') {
          return false;
        }

        return !!error && count < MAX_RETRIES;
      },
    });
    /* eslint-disable consistent-return */

    const httpLink = ApolloLink.split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable: wsCable }),
      new HttpLink({
        uri,
        fetchOptions: {
          referrerPolicy: 'unsafe-url',
        },
        headers: {
          accept: 'application/json',
          'Accept-Language': locale,
        },
        fetch: async (
          requestUri: URL | RequestInfo,
          options: RequestInit = {}
        ) => {
          const { operationName, operationId } = getOperationNameAndId(
            options.body
          );

          activeQueries.current += 1;
          if (!deviceFingerprint.current) {
            deviceFingerprint.current = await generateDeviceFingerprint();
          }
          options.headers = {
            DEVICE_FINGERPRINT: deviceFingerprint.current,
            ...options.headers,
            ...(apiKey.current && { APIKEY: apiKey.current }),
          };

          if (routeObjects) {
            const routes = matchRoutes(
              routeObjects,
              // eslint-disable-next-line no-restricted-properties
              window.location.pathname,
              basename
            );
            if (routes && options.headers) {
              const normalized = getNormalizedName(routes);
              // @ts-expect-error SORARE_PATH_PATTERN
              options.headers.SORARE_PATH_PATTERN = normalized;
            }
          }

          const { method } = options;
          return Sentry.startSpan(
            {
              // Do not create a transaction for this span
              // Only record it if it has a parent span (page-load / navigation)
              onlyIfParent: true,
              op: 'http.client',
              name: `${method} ${requestUri} ${[operationName, operationId]
                .filter(Boolean)
                .join(' ')}`,
            },
            async span => {
              return Sentry.suppressTracing(async () =>
                authenticatedFetch(requestUri as string, options)
              ).then(response => {
                Sentry.setHttpStatus(span, response.status);
                return response;
              });
            }
          )
            .then(response => {
              // Hack to redirect to Cloudflare Access login page in dev
              if (
                (isStaging || isMockprod) &&
                response.url.startsWith('https://sorare.cloudflareaccess.com')
              ) {
                // eslint-disable-next-line no-restricted-properties
                window.location.replace(API_ROOT);
              }

              const contentType = response.headers.get('content-type');
              if (
                contentType &&
                !contentType.includes('application/json') &&
                response.status !== 500
              ) {
                response
                  // We need to clone the request to avoid
                  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#locked_and_disturbed_streams
                  .clone()
                  .text()
                  .then(body => {
                    sendSafeError(
                      new Error(
                        `Invalid content type ${contentType} for response ${body}`
                      )
                    );
                  });
              }
              return response;
            })
            .finally(() => {
              activeQueries.current -= 1;
            });
        },
        credentials: 'include',
      })
    );

    const afterwareLink = new ApolloLink((operation, forward) => {
      return forward
        ? forward(operation).map(response => {
            const ctx = operation.getContext();
            if (!ctx.response) return response;

            const {
              response: { headers },
            } = ctx;
            if (headers?.get(xsrfReceivingHeaderName)) {
              const expires = new Date();
              expires.setFullYear(expires.getFullYear() + 1);

              cookie.save(
                xsrfCookieName,
                headers.get(xsrfReceivingHeaderName),
                {
                  path: '/',
                  // make sure the cookie is available on subdomains
                  domain: HOSTNAME.replace(/.*\.sorare\./, 'sorare.'),
                  expires,
                }
              );
            }
            if (
              import.meta.env.MODE === 'development' &&
              headers?.get('X-bullet-footer-text')
            ) {
              notificationsQueue.addNotification(
                'errors',
                {
                  errors: JSON.parse(headers?.get('X-bullet-footer-text')),
                },
                {
                  level: Level.ERROR,
                }
              );
            }

            return response;
          })
        : null;
    });

    return ApolloLink.from(
      [
        debugLink,
        tmLink,
        afterwareLink,
        OperationStoreClient()?.apolloLink,
        retryLink,
        onErrorLink,
        httpLink,
      ].filter(Boolean)
    );
  }, [
    wsCable,
    uri,
    locale,
    debugLink,
    tmLink,
    disableGraphQLErrorsReport,
    isMobileApp,
    sendSafeError,
    generateDeviceFingerprint,
    basename,
    routeObjects,
  ]);

  const client = useMemo(() => {
    return new ApolloClient({
      link,
      cache: coreCache,
      assumeImmutableResults: true,
      resolvers: {},
      defaultOptions: {
        mutate: {
          // workaround for https://github.com/apollographql/apollo-client/issues/10081
          update: () => {},
        },
      },
    });
  }, [link]);
  apolloClient.current = client;
  preloadQuery.current = createQueryPreloader(client);
  if (apolloClient.current && !isInitialized) {
    // only render children when the client is initialized
    // to avoid undefined apolloClient in route loaders
    initializeOptimizeQuery(() => {
      setIsInitialized(true);
    });
  }

  const refreshWsCable = useCallback(() => {
    setWsCable(cable());
    return null;
  }, [setWsCable]);

  return (
    <GraphqlContext.Provider
      value={{
        refreshWsCable,
        setApiKey,
        activeQueries,
      }}
    >
      <ApolloProvider client={client}>
        {isInitialized && children}
      </ApolloProvider>
    </GraphqlContext.Provider>
  );
};

export const GraphqlProvider = (props: Props) => {
  return (
    <DebugProvider>
      <StaticLocaleGraphqlProvider {...props} />
    </DebugProvider>
  );
};
