import { FormattedMessage } from 'react-intl';

import { HeadlineL, LabelM } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import {
  BaseballItemsStepImage,
  ClubShopInformationDialog as ClubShopInformationDialogDumb,
  CoinsStepImage,
  StepTextList,
  StepWrapper,
  howToGetCoinsMessages,
  howToUseCoinsMessages,
} from '@sorare/core/src/components/clubShop/ClubShopInformationDialog';

type Props = { open: boolean; onClose: () => void };
export const ClubShopInformationDialog = ({ open, onClose }: Props) => {
  return (
    <ClubShopInformationDialogDumb
      open={open}
      steps={[
        <StepWrapper key="step1">
          <CoinsStepImage />
          <HeadlineL as="h3" className="text-center">
            <FormattedMessage
              id="Baseball.ClubShop.OnboardingDialog.Step1.Title"
              defaultMessage="Earn Coins via Competitions"
            />
          </HeadlineL>
          <LabelM color="var(--c-nd-600)">
            <FormattedMessage
              id="Baseball.ClubShop.OnboardingDialog.Step1.Content1"
              defaultMessage="At the end of every Game Week — Tuesdays and Fridays — you earn Coins based on your Sorare: MLB teams’ scores (total of the individual competition scores)."
            />
          </LabelM>
          <LabelM color="var(--c-nd-600)">
            <FormattedMessage {...howToGetCoinsMessages.content2} />
          </LabelM>
        </StepWrapper>,
        <StepWrapper key="step2">
          <BaseballItemsStepImage />
          <HeadlineL as="h3" className="text-center">
            <FormattedMessage {...howToUseCoinsMessages.title} />
          </HeadlineL>
          <LabelM color="var(--c-nd-600)">
            <FormattedMessage {...howToUseCoinsMessages.content1} />
          </LabelM>
          <StepTextList>
            <li>
              <LabelM color="var(--c-nd-600)">
                <FormattedMessage
                  {...howToUseCoinsMessages.content3}
                  values={{
                    Bold,
                  }}
                />
              </LabelM>
            </li>
            <li>
              <LabelM color="var(--c-nd-600)">
                <FormattedMessage
                  id="Baseball.ClubShop.OnboardingDialog.Step2.Swaps"
                  defaultMessage="<Bold>Swaps</Bold> – Grab an extra swap or 10 and trade in cards from your gallery. Never worry about the 24h timer again."
                  values={{ Bold }}
                />
              </LabelM>
            </li>
            <li>
              <LabelM color="var(--c-nd-600)">
                <FormattedMessage
                  {...howToUseCoinsMessages.content4}
                  values={{ Bold }}
                />
              </LabelM>
            </li>
          </StepTextList>
        </StepWrapper>,
      ]}
      onClose={onClose}
    />
  );
};
