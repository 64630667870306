import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

export const Wrapper = styled(Vertical).attrs({ gap: 3, center: true })`
  justify-content: flex-start;
  padding: var(--triple-unit);
`;

export const TwoColumn = styled(Vertical).attrs({ gap: 3, center: true })`
  width: 100%;
  align-items: flex-start;
  @media ${laptopAndAbove} {
    flex-wrap: nowrap;
    flex-direction: row;
    gap: var(--quadruple-unit);
  }
`;

export const Column = styled(Vertical)`
  flex: 1;
  width: 100%;
  @media ${laptopAndAbove} {
    max-width: 410px;
  }
`;

export const BorderedBox = styled.div`
  padding: var(--double-unit);
  border: 1px solid var(--c-nd-200);
  border-radius: var(--double-unit);
`;

export const Content = styled(Horizontal)`
  flex-grow: 1;
  width: 100%;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
`;
