import { useState } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  ConversionCreditWithAmounts,
  getBestConversionCredit,
  useConversionCredits,
} from '@sorare/core/src/hooks/useConversionCredits';
import { useLifecycle } from '@sorare/core/src/hooks/useLifecycle';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';

type Props = {
  sport: Sport;
  currentlyUsedConversionCredit?: ConversionCreditWithAmounts;
  canUseConversionCredit: boolean;
  amount: MonetaryAmountOutput;
  defaultConversionCredit?: ConversionCreditWithAmounts;
};

export const useConversionCreditSelector = ({
  sport,
  currentlyUsedConversionCredit,
  canUseConversionCredit,
  amount,
  defaultConversionCredit,
}: Props) => {
  const { lifecycle } = useLifecycle();
  const { conversionCredits, loading } = useConversionCredits({
    sport,
    currentlyUsedConversionCredit,
    forceRefetch: true,
  });

  const availableConversionCredits = conversionCredits?.filter(
    cc => !cc.disabled
  );

  const proposedConversionCredit = getBestConversionCredit(
    amount,
    availableConversionCredits || []
  );

  const [conversionCredit, setConversionCredit] = useState<
    ConversionCreditWithAmounts | undefined
  >();
  const [conversionCreditInitialized, setConversionCreditInitialized] =
    useState<boolean>(false);

  if (
    !loading &&
    !conversionCreditInitialized &&
    availableConversionCredits?.length &&
    !conversionCredit &&
    lifecycle &&
    !lifecycle?.unselectConversionCreditByDefaultFromSettings
  ) {
    setConversionCreditInitialized(true);
    setConversionCredit(
      defaultConversionCredit ||
        currentlyUsedConversionCredit ||
        proposedConversionCredit
    );
  }

  const conversionCreditToUse = canUseConversionCredit
    ? conversionCredit
    : undefined;

  return {
    showConversionCredit:
      canUseConversionCredit &&
      availableConversionCredits &&
      availableConversionCredits?.length > 0,
    conversionCreditId: conversionCreditToUse?.id,
    conversionCredit: conversionCreditToUse,
    conversionCredits,
    setConversionCredit,
  };
};
