import { faCreditCardAlt } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { BodyM } from 'atoms/typography';
import { PaymentMethodIcon } from 'components/paymentMethods/Icon';

const Row = styled(Horizontal).attrs({ gap: 1.5 })``;

export const NewCreditCard = () => {
  return (
    <Row>
      <PaymentMethodIcon icon={faCreditCardAlt} />
      <BodyM color="var(--c-white)">
        <FormattedMessage
          id="NewPaymentBox.methods.newCreditCard"
          defaultMessage="New credit card"
        />
      </BodyM>
    </Row>
  );
};
