import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ComponentProps, ReactNode, useRef } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from 'atoms/icons';
import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';

const StyledButton = styled(Button)`
  text-align: left;
  justify-content: space-between;
`;
const Option = styled(ButtonBase)`
  width: 100%;
  text-align: left;
  gap: var(--unit);
  min-width: 320px;
  padding: var(--intermediate-unit) var(--double-unit);
  transition: 0.2s ease-in-out;

  [role='dialog'] & {
    &:first-of-type {
      border-radius: var(--intermediate-unit) var(--intermediate-unit) 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 var(--intermediate-unit) var(--intermediate-unit);
    }
  }

  &.active,
  &:hover {
    background: var(--c-nd-50);
  }
`;

type Props<T> = {
  value: T | undefined;
  options: {
    value: NoInfer<T>;
    label: string | ReactNode;
    disabled?: boolean;
    to?: To;
    replace?: boolean;
  }[];
  onChange?: (value: NoInfer<T>) => void;
  placeholder?: string | ReactNode;
} & Pick<ComponentProps<typeof Button>, 'disabled' | 'size'>;

export const DropdownSelect = <T extends string>({
  value,
  options,
  onChange,
  placeholder,
  ...buttonProps
}: Props<T>) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const selectedOption = options.find(option => option.value === value);

  const triggerContent = selectedOption?.label || placeholder;

  return (
    <Dropdown
      gap={4}
      label={({ open, ...dropdownTriggerProps }) => (
        <StyledButton
          ref={btnRef}
          disableDebounce
          color="tertiary"
          className={classNames({ active: open })}
          endIcon={<FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />}
          {...dropdownTriggerProps}
          {...buttonProps}
        >
          {typeof triggerContent === 'string' ? (
            <LabelM bold>{triggerContent}</LabelM>
          ) : (
            triggerContent
          )}
        </StyledButton>
      )}
      scrollable
    >
      {({ closeDropdown }) => (
        <Vertical gap={0}>
          {options.map(
            ({ value: optionValue, label, disabled, to, replace }) => {
              return (
                <Option
                  key={optionValue}
                  className={classNames({ active: optionValue === value })}
                  disabled={disabled}
                  onClick={() => {
                    onChange?.(optionValue);
                    closeDropdown();
                  }}
                  {...(to && {
                    to,
                    replace,
                  })}
                >
                  {typeof label === 'string' ? (
                    <LabelM bold>{label}</LabelM>
                  ) : (
                    label
                  )}
                </Option>
              );
            }
          )}
        </Vertical>
      )}
    </Dropdown>
  );
};
