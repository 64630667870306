import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styled from 'styled-components';

const Root = styled.button`
  font: var(--t-label-m);
  color: var(--c-nd-600);
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: var(--c-nd-900);
  }
`;

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const CartAction = ({ children, type = 'button', ...rest }: Props) => {
  return (
    <Root type={type} {...rest}>
      {children}
    </Root>
  );
};
